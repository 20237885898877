/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "item"];

  itemTargetConnected() {
    this.updateItemsFromUserInput();
  }

  toggleItem(event) {
    const currentValues = this.#toValues(this.inputTarget.value);
    const subject = event.target.value.toLowerCase().trim();

    const newValues = currentValues.includes(subject)
      ? currentValues.filter((value) => value !== subject)
      : currentValues.concat([subject]);

    this.inputTarget.value = newValues.join(", ");
    this.inputTarget.dispatchEvent(new CustomEvent("input"));
  }

  updateItemsFromUserInput() {
    const items = this.#toValues(this.inputTarget.value);

    this.itemTargets.forEach((element) => {
      element.checked = element.value.length && this.#toValues(element.value).some((value) => items.includes(value));
    });
  }

  #toValues(value) {
    return value
      .toLowerCase()
      .split(",")
      .map((part) => part.trim())
      .filter(String);
  }
}
