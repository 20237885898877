/* eslint-env browser */
/* eslint-disable no-underscore-dangle */
/* global _ */

import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import AutoNumeric from "autonumeric";
import { fetchStream } from "../../helpers/fetch";

export default class extends Controller {
  static targets = [
    "submitButton",
    "overwriteButton",
    "toggleNickname",
    "emailFieldset",
    "smsFieldset",
    "totalOrderAmount",
    "totalOrders",
    "form",
    "modal",
  ];

  static values = {
    currencySymbol: String,
    selectedOrders: Array,
    translatedStrings: {
      type: Object,
      default: { save_payment_request: "Submit", send_payment_request: "Submit" },
    },
  };

  formTargetConnected(form) {
    this.selectedOrdersValue = JSON.parse(form.dataset.initialOrders ?? "[]");

    setTimeout(() => {
      this.element.querySelector("[data-fieldsets-name-param='email']").click();
      this.notificationTypeChanged({ params: { notificationType: "email" } });
    });
  }

  totalOrdersTargetConnected() {
    this.totalOrdersTarget.textContent = `${this.selectedOrders.length} selected`;
  }

  totalOrderAmountTargetConnected() {
    new AutoNumeric(this.totalOrderAmountTarget, { currencySymbol: this.currencySymbolValue }).set(
      this.totalOrderAmount,
    );
  }

  selectedOrdersValueChanged() {
    if (this.hasTotalOrdersTarget) {
      this.totalOrdersTarget.textContent = `${this.selectedOrders.length} selected`;
    }

    if (this.hasTotalOrderAmountTarget) {
      AutoNumeric.set(this.totalOrderAmountTarget, this.totalOrderAmount);
    }

    if (this.hasToggleNicknameTarget) {
      this.toggleNicknameTarget.classList.toggle("hidden", this.selectedOrders.length < 2);
    }

    this.updateFormStatus();
  }

  notificationTypeChanged({ params }) {
    this.updateFormStatus();

    this.submitButtonTarget.textContent =
      params.notificationType === undefined
        ? this.translatedStringsValue.save_payment_request
        : this.translatedStringsValue.send_payment_request;
  }

  orderUpdated(event) {
    const order = this.preparedOrderData(event.detail.order);
    const input = this.element.querySelector(`#customer_statement_requested_${order.order_id}`);

    if (!input) return;

    if (event.detail.checked) {
      if (!input.valueAsNumber && event.target.type === "checkbox") {
        this.updateOrderAmount(input, order, order.amountOutstanding);
      }

      this.selectedOrdersValue = [...this.selectedOrdersValue, order];
    } else {
      this.updateOrderAmount(input, order, "0.00");

      if (order.id) {
        this.selectedOrdersValue = [...this.selectedOrdersValue, { ...order, _destroy: "1" }];
      }
    }

    this.validateForm();
  }

  preparedOrderData(dataset) {
    const { orderId, orderVisualId, statementOrderId, amount, outstanding, amountOutstanding } = dataset;
    const order = {
      amount,
      outstanding,
      amountOutstanding,
      id: statementOrderId,
      order_id: orderId,
      order_visual_id: orderVisualId,
    };

    this.selectedOrdersValue = this.selectedOrdersValue.filter(
      (item) => String(item.order_id) !== String(order.order_id),
    );

    return order;
  }

  updateOrderAmount(input, order, value) {
    const checkbox = this.element.querySelector(`input[type=checkbox][data-order-id='${order.order_id}']`);

    order.amount = value;
    checkbox.dataset.amount = value;
    AutoNumeric.set(input, value);
  }

  updateFormStatus() {
    if (this.selectedOrders.length === 0 || !this.validateForm()) {
      this.disableSubmissionButtons(true);
      return;
    }

    if (this.emailFieldsetTarget.disabled === false) {
      this.disableSubmissionButtons(!this.canSendEmails);
    }

    if (this.smsFieldsetTarget.disabled === false) {
      this.disableSubmissionButtons(!this.canSendTextMessages);
    }

    if (this.emailFieldsetTarget.disabled && this.smsFieldsetTarget.disabled) {
      this.disableSubmissionButtons(false);
    }
  }

  validateForm(event = null) {
    const valid = this.formTarget.checkValidity();

    this.formTarget.querySelectorAll("[id^='customer_statement_requested']:valid").forEach((item) => {
      this.requestAmountValid(item, true);
    });

    if (!valid) {
      event?.preventDefault();

      this.formTarget.querySelectorAll("[id^='customer_statement_requested']:invalid").forEach((item) => {
        this.requestAmountValid(item, false);
      });
    }

    return valid;
  }

  requestAmountValid(item, valid) {
    item.classList.toggle("!border-red-500", !valid);
    item.nextElementSibling.classList.toggle("hidden", valid);
  }

  async submitForm(event) {
    if (!this.validateForm(event)) return;

    if (this.hasOrderWithExistingRequest && !this.overwriteButtonTarget.contains(event.target)) {
      this.element.querySelector("[data-component='outstanding_requests'] dialog").showModal();
      return;
    }

    this.disableSubmissionButtons(true);

    const response = await fetchStream(this.formTarget.action, {
      method: (this.formTarget.querySelector("[name='_method']")?.value ?? this.formTarget.method).toUpperCase(),
      body: { statement: this.formData(this.formTarget) },
    });

    if (!response.ok) {
      window.displayFlash("error", (await response.json()).message, this.element.querySelector("dialog"));
    } else {
      this.dispatch("success");
      this.dispatch("close");

      Turbo.renderStreamMessage(await response.text());

      window.displayFlash("success", this.translatedStringsValue.success);
    }

    this.disableSubmissionButtons(false);
  }

  disableSubmissionButtons(disable) {
    this.submitButtonTarget.disabled = disable;

    if (this.hasOverwriteButtonTarget) {
      this.overwriteButtonTarget.disabled = disable;
    }
  }

  formData(form) {
    const elements = Array.from(form.querySelectorAll("input, select, textarea"));

    return Object.fromEntries(
      elements
        .filter(
          (element) =>
            element.name.length &&
            !element.disabled &&
            !["authenticity_token", "_method"].includes(element.name) &&
            !element.name.includes("reminders_attributes") &&
            !element.closest("fieldset")?.disabled,
        )
        .concat({ name: "statement_orders_attributes", value: this.statementOrderAttributes })
        .concat({ name: "reminders_attributes", value: this.statementRemindersAttributes })
        .map((element) => [element.name.replace(/statement\[(\w+)\]/, "$1"), element.value]),
    );
  }

  get canSendEmails() {
    return (
      this.emailFieldsetTarget.querySelector("[data-component='email_value']").value.match(/[a-zA-Z0-9]+/g)?.length &&
      this.emailFieldsetTarget.querySelector("[data-component='email_message']").value.match(/[a-zA-Z0-9]+/g)?.length
    );
  }

  get canSendTextMessages() {
    return (
      !this.smsFieldsetTarget.disabled &&
      this.smsFieldsetTarget.querySelector("[data-component='phone_value']")?.value?.match?.(/\d+/g)?.length &&
      this.smsFieldsetTarget.querySelector("[data-component='phone_message']")?.value?.match?.(/[a-zA-Z0-9]+/g)?.length
    );
  }

  get hasOrderWithExistingRequest() {
    return this.selectedOrdersValue.some((item) => item.outstanding);
  }

  get selectedOrders() {
    return this.selectedOrdersValue.filter((order) => !order._destroy);
  }

  get statementOrderAttributes() {
    return this.selectedOrdersValue.map((order) => _.pick(order, "id", "order_id", "amount", "_destroy"));
  }

  get statementRemindersAttributes() {
    return Array.from(
      this.element.querySelectorAll("fieldset:not(:disabled) .reminder-field > [data-component='reminderInDays']"),
    ).map((reminder) => ({
      reminder_in_days: reminder.value,
    }));
  }

  get totalOrderAmount() {
    return this.selectedOrders.reduce((total, order) => total + parseFloat(order.amount || 0), 0);
  }
}
