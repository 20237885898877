/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  toggle({ params }) {
    this.fieldsets.forEach((element) => {
      element.disabled = element.dataset.fieldsetName !== params.name;
    });
  }

  get fieldsets() {
    return this.element.querySelectorAll("fieldset");
  }
}
