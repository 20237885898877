/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    containerSelector: String,
  };

  remove(event) {
    event.target.closest(this.containerSelectorValue).remove();
  }
}
