/* global CustomEvent */

import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";
import useFocusTrap from "../../../frontend/composables/use_focus_trap";

export default class extends Controller {
  static targets = ["button", "childComponent", "defaultLabel", "menu", "reset", "selectedLabel", "focus"];

  static values = {
    id: String,
    defaultLabel: String,
    selected: String,
    selectionChanged: Boolean,
    selectedClasses: {
      type: Array,
      default: [],
    },
    state: {
      type: Number,
      default: 0,
    },
  };

  states = {
    Closed: 0,
    Open: 1,
  };

  connect() {
    useClickOutside(this);
    useFocusTrap(this, { element: this.menuTarget });

    this.menuTarget.setAttribute("aria-labelledby", this.idValue);

    this.buttonTarget.setAttribute("id", this.idValue);

    if (this.hasSelectedLabelTarget) {
      this.selectedLabelTarget.textContent = this.selectedLabelText();
      this.selectedValueChanged(this.selectedValue);
      this.highlightSelection(this.selectedValue);
    }

    this.initialized = true;
  }

  cancelFocusTrap() {
    if (this.stateValue === this.states.Closed) {
      return;
    }

    this.stateValue = this.states.Closed;

    if (this.dropdownIsButtonElement()) {
      this.buttonTarget.focus();
    }
  }

  dropdownIsButtonElement() {
    return this.hasButtonTarget && this.buttonTarget.tagName === "BUTTON";
  }

  clickOutside() {
    this.close();
  }

  close() {
    this.stateValue = this.states.Closed;
  }

  open() {
    this.stateValue = this.states.Open;
  }

  updateButtonLabel(event) {
    const { label } = event.detail;

    if (this.dropdownIsButtonElement) {
      this.buttonTarget.querySelector("[data-component='button-label']").innerText = label;
      this.highlightSelection(label);
    }
  }

  updateSelectedValue(event) {
    const { value, selectionChanged } = event.detail;
    if (selectionChanged === true || selectionChanged === false) {
      this.selectionChangedValue = selectionChanged;
    }
    this.selectedValue = value;

    this.highlightSelection(this.selectedValue);

    if (this.hasSelectedLabelTarget) {
      this.selectedLabelTarget.textContent = this.selectedLabelText();
    }
  }

  clearSelection(event) {
    event.stopPropagation();
    this.updateSelectedValue({ detail: { value: "", selectionChanged: false } });

    if (this.hasDefaultLabelTarget) {
      this.defaultLabelTarget.click();
    }

    if (this.hasDefaultLabelValue) {
      this.updateButtonLabel({ detail: { label: this.defaultLabelValue } });
    }

    this.childComponentTargets.forEach((child) => {
      child.dispatchEvent(
        new CustomEvent("dropdown:cleared", {
          target: child,
        }),
      );
    });
    this.dispatch("closed", { detail: { dropdownName: this.idValue } });
  }

  highlightSelection(selection) {
    const defaultState =
      selection === "" ||
      selection === false ||
      (this.hasDefaultLabelTarget && this.defaultLabelTarget.dataset.value === selection) ||
      (this.hasDefaultLabelValue && this.defaultLabelValue === selection);

    this.selectedClassesValue.forEach((className) => {
      this.buttonTarget.classList.toggle(className, !defaultState);
    });

    const resetFilter = this.buttonTarget.querySelector("[data-role=reset-filter]");
    resetFilter?.classList.toggle("hidden", defaultState);
  }

  selectedLabelText() {
    const selectedLabel = this.menuTarget
      .querySelector(`[data-value="${this.selectedValue}"]`)
      ?.getAttribute("selected_label");
    if (selectedLabel !== null) {
      return selectedLabel;
    }
    const label = this.menuTarget.querySelector(`[data-value="${this.selectedValue}"]`)?.getAttribute("label");
    return label ?? this.menuTarget.querySelector(`[data-value="${this.selectedValue}"]`)?.textContent ?? this.defaultLabelValue ?? "";
  }

  selectedValueChanged(value) {
    this.menuTarget
      .querySelectorAll("a:not([disabled]), button:not([disabled])")
      .forEach((element) => element.setAttribute("aria-selected", String(element.dataset.value === value)));

    this.configureDropdownButton(value);
  }

  configureDropdownButton(value) {
    const children = Array.from(this.buttonTarget.querySelectorAll("*"));
    const dropdownHasText = children.find((node) => node.textContent.replace(/\s/g, "") !== "");
    if (!dropdownHasText && (this.selectionChangedValue === true || this.selectionChangedValue === false)) {
      this.element.classList.toggle("selection-changed", this.selectionChangedValue);
    } else {
      this.element.classList.toggle("item-selected", value !== "");
    }
  }

  stateValueChanged(value, oldValue) {
    if (this.hasButtonTarget) {
      this.buttonTarget.setAttribute(
        "aria-expanded",
        {
          [this.states.Open]: "true",
          [this.states.Closed]: "false",
        }[value],
      );

      if (value === this.states.Open && this.dropdownIsButtonElement()) {
        if (this.hasFocusTarget) {
          this.focusTarget.focus();
        } else {
          this.menuTarget.focus();
        }
      }

      if (value === this.states.Closed && this.initialized && value !== oldValue) {
        this.dispatch("closed", { detail: { dropdownName: this.idValue } });
      }
    }
  }

  toggle() {
    this.stateValue = this.stateValue === this.states.Open ? this.states.Closed : this.states.Open;
  }
}
