/* eslint-env browser */

import { Controller } from "stimulus";
import AutoNumeric from "autonumeric";

export default class extends Controller {
  static values = {
    options: {
      type: Object,
      default: {},
    },
  };

  connect() {
    if (AutoNumeric.isManagedByAutoNumeric(this.element)) {
      return;
    }

    new AutoNumeric(this.element, this.optionsValue).set(this.element.value);
  }
}
