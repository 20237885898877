/* eslint-env browser */

import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { useDebounce } from "stimulus-use";
import { fetchStream } from "../../../frontend/helpers/fetch"

export default class extends Controller {
  static debounces = [{ name: "search", wait: 300 }];

  static targets = ["input", "option", "defaultLabel"];

  static values = {
    frame: String,
    searchUrl: String,
    searchParams: {
      type: Object,
      default: {},
    },
    maxResults: {
      type: Number,
      default: 4,
    },
  };

  connect() {
    useDebounce(this);
    this.search();
  }

  inputTargetConnected() {
    useDebounce(this, { wait: 300 });
  }

  async search() {
    const params = new URLSearchParams({
      query: this.inputTarget.value,
      limit: this.maxResultsValue,
      frame: this.frameValue,
      ...this.searchParamsValue,
    });

    const response = await fetchStream(`${this.searchUrlValue}?${params}`);

    if (!response.ok) return;

    Turbo.renderStreamMessage(await response.text());
  }
}
